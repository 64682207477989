var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-prompt",
        {
          attrs: {
            title: _vm.$t("vue.deleteDialog"),
            color: "danger",
            "cancel-text": _vm.$t("vue.cancel"),
            "accept-text": _vm.$t("vue.delete"),
            active: _vm.deletePrompt,
          },
          on: {
            cancel: function ($event) {
              _vm.selectedDialog = null
            },
            accept: function ($event) {
              return _vm.onDeleteDialog(_vm.selectedDialog.id)
            },
            close: function ($event) {
              _vm.selectedDialog = null
            },
            "update:active": function ($event) {
              _vm.deletePrompt = $event
            },
          },
        },
        [
          _vm.selectedDialog
            ? _c("div", { staticClass: "con-exemple-prompt" }, [
                _c("p", [
                  _c("strong", [_vm._v(_vm._s(_vm.selectedDialog.name))]),
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("vue.deleteDialogMessage")) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "vx-row embed-list-button-add" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full mb-8" },
          [
            _c(
              "vs-button",
              {
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-plus",
                  type: "filled",
                },
                on: { click: _vm.onAddDialog },
              },
              [_vm._v(_vm._s(_vm.$t("vue.addWebConnect")))]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "quick-guide-embedlist-holder" },
        [
          _vm.dialogs.length === 0 &&
          _vm.activeUserInfo &&
          !_vm.activeUserInfo.isQuickGuideStarted &&
          !_vm.activeUserInfo.isQuickGuideFinished &&
          !_vm.activeUserInfo.isQuickGuideInProgress
            ? _c("quick-guide-start")
            : _vm._e(),
          _vm.activeUserInfo &&
          _vm.activeUserInfo.isQuickGuideStarted &&
          _vm.activeUserInfo.isQuickGuideInProgress &&
          !_vm.activeUserInfo.isQuickGuideFinished
            ? _c("quick-guide-carousel")
            : _vm._e(),
        ],
        1
      ),
      _vm.dialogs.length > 0
        ? _c(
            "div",
            { staticClass: "vx-row" },
            _vm._l(_vm.widgets, function (dialog) {
              return _c(
                "div",
                {
                  key: dialog.id,
                  staticClass:
                    "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base dialog-wrapper",
                },
                [
                  _c(
                    "vx-card",
                    {
                      staticClass: "overflow-hidden dialog-container",
                      staticStyle: { cursor: "pointer" },
                    },
                    [
                      _c("div", { staticClass: "dialog__heading" }, [
                        _c(
                          "div",
                          { staticClass: "w-full flex justify-between" },
                          [
                            _c("div", [
                              _c(
                                "h2",
                                { staticClass: "pr-3 font-bold dialog__title" },
                                [_vm._v(_vm._s(dialog.name))]
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "flex items-center justify-end" },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("vs-switch", {
                                      attrs: { color: "#275D73" },
                                      on: {
                                        input: function ($event) {
                                          return _vm.updateDialogVisibility(
                                            dialog
                                          )
                                        },
                                      },
                                      model: {
                                        value: dialog.isEnabled,
                                        callback: function ($$v) {
                                          _vm.$set(dialog, "isEnabled", $$v)
                                        },
                                        expression: "dialog.isEnabled",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "mt-auto text-center dialog__actions" },
                        [
                          _c("feather-icon", {
                            staticClass:
                              "inline-flex rounded-full dialog__icon",
                            style: {
                              background: "rgba(var(--vs-primary),.15)",
                              color: "#275D73",
                            },
                            attrs: { icon: "EditIcon" },
                            on: {
                              click: function ($event) {
                                return _vm.onEditDialog(dialog.id)
                              },
                            },
                          }),
                          _c("feather-icon", {
                            staticClass:
                              "inline-flex rounded-full dialog__icon",
                            style: {
                              color: "rgb(28, 132, 57)",
                              background: "rgb(210, 230, 215)",
                            },
                            attrs: { icon: "SettingsIcon" },
                            on: {
                              click: function ($event) {
                                return _vm.onEditSettings(dialog.id)
                              },
                            },
                          }),
                          _c("feather-icon", {
                            staticClass:
                              "inline-flex rounded-full text-danger dialog__icon",
                            style: { background: "rgba(var(--vs-danger),.15)" },
                            attrs: { icon: "TrashIcon" },
                            on: {
                              click: function ($event) {
                                ;(_vm.selectedDialog = {
                                  id: dialog.id,
                                  name: dialog.name,
                                }),
                                  (_vm.deletePrompt = true)
                              },
                            },
                          }),
                          _c("feather-icon", {
                            staticClass:
                              "inline-flex rounded-full text-warning dialog__icon",
                            style: {
                              background: "rgba(var(--vs-warning),.15)",
                            },
                            attrs: { icon: "CopyIcon" },
                            on: {
                              click: function ($event) {
                                return _vm.onCopyWidget(dialog.id)
                              },
                            },
                          }),
                          !(!_vm.HAS_ABTESTING_ACCESS && _vm.isMobileApp)
                            ? _c(
                                "div",
                                {
                                  staticClass: "dialog-widgetbox-footer",
                                  attrs: { align: "center" },
                                },
                                [
                                  dialog.isVegaWidget &&
                                  dialog.isVegaWidgetEnabled &&
                                  (dialog.vega ||
                                    (dialog.vegaPopupThreshold &&
                                      dialog.vegaPopupThreshold.length > 0))
                                    ? _c("VegaActivatedIcon", {
                                        staticClass:
                                          "dialog-widgetbox-footer--icon",
                                      })
                                    : _vm._e(),
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass:
                                        "rad-25 dialog__button dialog__button_filled",
                                      class: {
                                        dialog__button_disabled:
                                          !_vm.HAS_ABTESTING_ACCESS,
                                      },
                                      attrs: {
                                        icon: !_vm.HAS_ABTESTING_ACCESS
                                          ? "lock"
                                          : "",
                                        type: "filled",
                                        color: "warning",
                                        disabled:
                                          dialog.type === "custom" ||
                                          !_vm.HAS_ABTESTING_ACCESS,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.abTesting(dialog.id)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("routes.abtesting")) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }